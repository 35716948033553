<template>
  <div>
    <v-btn color="primary" large @click="newUser">
      {{ $t('NEW USER') }}
    </v-btn>
  </div>
</template>
<script>
export default {
  components: {},
  data: () => ({}),
  methods: {
    async newUser() {
      let me = this
      await me.$store.commit('users/newUserToEditor', me.$store.state)
      await me.$store.commit('users/set', [
        'editor.organisation',
        me.$route.params.organisationId
      ])
      await me.$redirect({
        name: 'user-creator'
      })
    }
  }
}
</script>
